.CustomIcon {
	height: 2em!important;
	width:2em!important;

	&.external-link{
		background-color:transparent;
		position: absolute;
		margin-top: -12px;
		margin-left: 35px;
		width: 1rem !important;
		height: 14px;
		fill: darkgrey;
		padding-right: 3px;
		padding-top: 3px;
	}
}
