.OlMapContextMenu.MuiPaper-root.MuiPaper-rounded {
	border-radius: 2px;
	.MuiList-root.MuiList-padding.MuiList-subheader {
		padding: 0;
		.MuiListSubheader-root.MuiListSubheader-sticky.MuiListSubheader-gutters {
			background: #efefef!important;
			padding:0!important;
			font-size: 16px!important;
			text-align: center;
			line-height: 38px;
			color: #292929;
		}
		.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
			padding: 6px 12px;
			&:hover {
			  text-decoration: underline;	
			  cursor: pointer;
			}
			.MuiListItemIcon-root {
				min-width: 20px;
				svg.MuiSvgIcon-root {
					fill: #212121!important;
					font-size: 14px;
				}
			}
			.MuiListItemText-root {
				span {
					font-size: 13px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}
		}
	}
}


