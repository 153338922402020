.CustomItemCount {
	width: 140px;
	background: #ddd !important;
	.CustomItemContainer {
		height: 100%;
		align-items: center;
	}
	&.countNumber {
		background: #5cb85c !important;
		color: #b3f2b3
	}
	.CustomItemCountIcon {
		.CustomIcon {
			font-size: 2rem;
			position: relative;
			margin-top: 2px;
		}
		
	}
	.CustomItemCountTitle {
		// border: 1px solid red;
		height: 30px;
		text-align:right;
		padding: 4px 8px 0 0;
	}
	.CustomItemCountNumber {
		// border: 1px solid red;
		font-size:2rem;
		text-align:right;
		padding-right: 8px;
	}
}