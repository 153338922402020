.HistorySlider {
	width: 100%;

	.customSlider {
		/* CROSS BROWSER SELECTOR*/
		margin-bottom:10px;
		.slider-progress {
			/* RESETS */
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			border: none;
   
			width: 100%;
			height:6px;
			border-radius: 1px;
			background: #efefef;
			color: #e74c3c;
		}
		/* WEBKIT BROWSERS - CHROME, OPERA AND SAFARI */
		.slider-progress::-webkit-progress-bar {
			background-color: #efefef;
			border-radius: 2px;
		}
		.slider-progress::-webkit-progress-value {
			background: #95a5ff;
			border-radius: 2px;
		}
		/* MOZILLA FIREFOX */
		.slider-progress::-moz-progress-value {
			background: #95a5ff;
			border-radius: 2px;
		}
		/* MICROSOFT EDGE & IE */
		.slider-progress::-ms-fill {
			background: #95a5ff;
			border-radius: 2px;
		}
		
		.slider-rail {
			border-radius: 1px;  
			background: #e0e0e0;
			width: calc(100% - 40px);
			height: 6px;		
			position:absolute;
			z-index: -1;
			box-sizing: content-box;
			touch-action: none;
			margin-top: 6px;
			display: block;
			.slider-track {
				height: 6px;
				display: block;
				position: absolute;
				border-radius: 1px;
				background: #95a5ff;
			}
			.slider-track-tooltip {
				position:absolute;
			}
			
			.tickMarks {
				// border: 1px solid orange;
				// width: 100%;
				flex: 1 1 0%;
				height: 6px;
				span {
					top: "12px";
					position: "relative";
					display: inline-flex;
					width: 2px;
					height: 6px;
					
					&.emergency {
						background: #d52b1e;
						z-index: 2;
					}
					&.report {
						background: #222b30;
						z-index: 1;
					}
					&.speed {
						background: #c43cc4;
						z-index: 2;
					}
					&.cargo {
						background: #148c90;
						z-index: 2;
					}
					&.geofence {
						background: #f9840f;
						z-index: 2;
					}
					&.non-report {
						background: #67d06c;
						z-index: 2;
					}
					&.vehicle {
						background: #587fab;
						z-index: 2;
					}
				}
			}
		}
		
		.slider {
			-webkit-appearance: none;
			background: transparent;
			width: 100%;
			// margin-top: -14px;
			&::-webkit-slider-thumb {
				// display: none;
				-webkit-appearance: none;
				appearance: none;				
				border-radius: 50%; 
				background: #3f51b5;
				cursor: pointer;
				height: 16px;
				width: 16px;
				&:hover {
					box-shadow: 0px 0px 0px 14px rgba(63, 81, 181, 0.16);
				}
				
				&::after {
					top: -15px;
					left: -15px;
					right: -15px;
					bottom: -15px;
					content: "";
					position: absolute;
					border-radius: 50%;
				}
			}
			&::-webkit-slider-thumb:hover {
				box-shadow: 0px 0px 0px 8px rbga(63, 81, 181, 0.4);
			}
			&::-moz-range-thumb {
				width: 16px;
				height: 16px;
				border-radius: 50%;
				background: #3f51b5;
				cursor: pointer;
				// margin-top: 4px;
				position:relative;
				top: 4px;
				&::after {
					top: -15px;
					left: -15px;
					right: -15px;
					bottom: -15px;
					content: "";
					position: absolute;
					border-radius: 50%;
				}
				&:hover {
					box-shadow: 0px 0px 0px 14px rgba(63, 81, 181, 0.16);
				}
			}
		}

		
		
	}
	
	
	#tooltip
	{
	  position: absolute;
	  left: 0px;
	  top: -30px;
	  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
	  font-style: normal;
	  font-weight: 400;
	  letter-spacing: normal;
	  line-height: 1.42857143;
	  text-align: left;
	  text-align: start;
	  text-shadow: none;
	  text-transform: none;
	  white-space: normal;
	  word-break: normal;
	  word-spacing: normal;
	  word-wrap: normal;
	  font-size: 10px;
	  background:#3f51b5;
	  color: #fff;
	  padding: 4px 8px;
	  border-radius: 3px;
	  display: inline-block;
	  &.top
	  {
		margin-top: -5px;
	  }
	}
	  


	  
	///////////////////////////////////////////////////////////////////////////////////////
	// border: 1px solid red;
	.MuiSlider-root {
		.MuiSlider-rail {
			height: 6px;
		}
		.MuiSlider-track {
			height: 6px;
		}
		.MuiSlider-mark {
			height: 6px;
		}
		.MuiSlider-thumb {
			height: 16px;
			width: 16px;
			margin-top: -5px;
			margin-left: -7px;
		}
		.MuiSlider-valueLabel {
			left: -50%!important;
		}
		.MuiSlider-mark {
			background-color: red;
		}
		.MuiSlider-valueLabel {
			left: -210%!important;
			&>span {
				width: 84px;
				transform: none;
				border-radius: 1px;
				text-align: center;
				padding: 0 8px;
				&>span {
					transform: none;
					font-size: 1rem;
				}
			}
		}
	}
}