.MapComponent {
  .ol-attribution.ol-unselectable.ol-control {
    display: none;
  }
}

//copied from angular map.scss

#mapW {
  /* Main Map DIV */
  // add arrow to device/geofence/poi popup
  .ol-overlay-container.ol-selectable {
    width: 300px;
    height: auto !important;;
    &.top-center {
        .minimize {
            top: 0px;
        }
        .olMapPopupArrow {
            left: calc(50% - 10px);
            top: -9px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #f5f5f5;  
        }
    }
    &.top-right {
        .minimize {
            top: 0px;
        }
        .olMapPopupArrow {
            top: -9px;
            right: 4px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #f5f5f5;  
        }
    }
    &.top-left {
        .minimize {
            top: 0px;
        }
        .olMapPopupArrow {
            top: -9px;
            left: 4px;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #f5f5f5;  
        }
    }
    &.center-right {
        .minimize {
            top: calc(50% - 15px);
        }
        .olMapPopupArrow {
            top: calc(50% - 10px);
            right: -9px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #f5f5f5;  
        }
    }
    &.center-left {
        .minimize {
            top: calc(50% - 15px);
        }
        .olMapPopupArrow {
            top: calc(50% - 10px);
            left: -9px;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-right: 10px solid #f5f5f5;  
        }
    }
    &.bottom-center {
        .minimize {
            top: initial;
            bottom: 0;
        }
        .olMapPopupArrow {
            left: calc(50% - 10px);
            top: calc(100% - 1px);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #f5f5f5;  
        }
    }
    .olMapPopupArrow {
        position:absolute;
        z-index:1;
        width: 0;
        height: 0;
    }
}

}

#audioConfirmBox {
  position: absolute;
  width: 300px;
  height: 150px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: #fff;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
  transition: all 0.5s ease;
}

#audioConfirmOverLay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  transition: all 0.5s ease;
}

#audioConfirmText {
  font-size: 18px;
  font-family: "proxima-nova", sans-serif;
}

#audioConfirmButton {
  margin-top: 8px;
  color: #fff;
  background-color: #2e6da4;
}

