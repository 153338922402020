
   
/*
* ALERT RULES MODULE
*
*/

.ar-th-bg {
    background:#777;
    color: #fff;
}
.addContactForm > h3 {
    margin:0;
}
.Alert-Rule-menu .table > thead > tr > th, 
.Alert-Rule-menu .table > tbody > tr > th, 
.Alert-Rule-menu .table > thead > tr > td, 
.Alert-Rule-menu .table > tbody > tr > td {
  padding:4px 4px;
}

.Alert-Rule-menu .table > thead > tr > th {
    background-color: #ddd!important;
    font-size:12px;
}
.Alert-Rule-menu .table > tbody > tr td.errorField > input {
    border-color: red;
}
.Alert-Rule-menu .table tbody td > .btn {
  padding:0px 6px;
}
.Alert-Rule-menu .table tbody td > .btn,
.Alert-Rule-menu .table tbody td > input[type="checkbox"] {
  display: block;
  margin:auto;
}
.Alert-Rule-menu .table tbody td > input[type="checkbox"] {
   margin-top:4px;
}
.Alert-Rule-menu .table tbody td > input.input-sm {
    height: 24px;
    padding: 2px 4px;
}
.Alert-Rule-menu .table tbody td > .btn > i.fa {
  margin:0;
}
.ar-contact-list-thead > div {
    font-size:12px;
}


.Alert-Rule-menu .table > tbody:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.Alert-Rule-menu .mainMenuBox .nav-justified > li > a,
.Alert-Rule-menu .mainMenuBox .nav-justified > li.active > a,
.Alert-Rule-menu .mainMenuBox .nav-justified > li.active > a:focus,
.Alert-Rule-menu .mainMenuBox .nav-justified > li.active > a:hover {
    padding: 5px 2px!important;
}
.alert-types-box {
    margin:0;
}
.alert-types-box > label.checkboxCont {
    padding: 0;
    margin: 0;
    /* margin-right: 20px;
    border: 1px solid red; */
    width: calc(16.6% - 0px);
    float: left;
}
.editContactTableSearch .menu-search-box {
    margin-bottom:5px!important;
}
.editContactTableSearch .menu-search-box > .fa-search {
    right: 20px;
}


/*
* ALERT RULE MODULE
* edit Alert Rule form 
*/
.ar_edit_contacts {
    left: 0!important;
    width: 710px;
}
.ar_edit_contacts .close {
   position: absolute;
   right: 5px;
   top: 0;
   font-size: 24px;
}
.ar_edit_contacts i.fa {
    margin-right:0;
}
.selected-alerts .checkbox {
    margin:0;
    margin-bottom:5px;
}
.selected-alerts .checkbox label{
   font-size:12px;
   padding: 3px 0;
}
.alert_rule_tab,.getAssetGroups {
	padding: 5px 0!important;
}
.alert_rule_contact_email {
    margin-bottom: 4px;
    margin-right: 4px;
}
/* Contacts */
.alert_rule_contact_sms {
    margin-bottom: 4px;
    margin-right: 10px;
}
.alert_rule_contacts_box {
    height: auto;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* padding-right: 10px; */
    /* padding-top: 4px; */
}
.aler_rule_content_left > h4 > .label {
    position: absolute;
    font-size: 12px;
    top: 2px;
    /* right: 4px; */
}
.ar_contactlist_name label,
.ar_contactlist_client label {
    margin-left: 8px!important;
}
.ar_contactlist_client > i.fa,
.ar_contactlist_name > i.fa {
    margin-top: 4px!important;
}
.ar_contactlist_client [type=checkbox] {
    margin-top: 4px!important;
}
.ar_contactlist_ckeckboxes {
  float:right;
   margin-left: 15px;
}
.ar_edit_img {
    background: #fff;
    z-index: 20;
}
/* Users */
.ar_contactlist_client {
    width: 100%;
    float: left;
}
.alert_rule_subgroup,.scription_subgroup {
	padding-left:20px;
}
li.ar_contactlist_name > label{
    cursor:pointer;
}
li.ar_contactlist_name > label > i {
    margin-top: 4px;
    margin-left: 10px!important;
    opacity:0;
}
li.ar_contactlist_name > label:hover > i {
    opacity:1;
}
.alert_rule_subgroup .selected-subscribers > p {
    margin:0;
}
.alert_rule_subgroup .selected-subscribers > p:last-child {
    background-color: #e5e5e5;
}
.alert_rule_subgroup .divider {
    height: 1px;
    margin: 10px 0 10px;
    overflow: hidden;
    background-color: #e5e5e5;
    width:100%;
    float:left;
}
.subscribers {
	width:100%;
}
.subscribers > li {
	width: 100%;
    float: left;
    margin-bottom: 2px;
    background: #ddd;
    border-top: 1px dotted#ccc;
	padding: 5px 0;
}
.subscribers > li label {
    font-size: 12px;
    margin: 0;
    width: auto;
    /* padding: 10px 0 0 10px; */
    /* margin-top: -80px; */
    position: relative;
    top: -2px;
    left: 5px;
}
.alert_rule_subgroup .assets_in_groups li input[type=checkbox] {
    margin: 6px 5px 0 5px;
}
.alert_rule_tabs_nav > li {
    width:33.3%
}
.alert_rule_tabs_nav > li > a {
    padding: 10px 1px;
    width: 100%;
    text-align: center;
}
