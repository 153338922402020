.mapToolsPopover {
	
	h5 {
		font-weight: bold;
	}
	.mapToolsMenu {
		li {
			padding: 10px 0;
			border-bottom: 1px solid #eee;
			width: 100%;
			float: left;
			> .MuiButtonBase-root {
				padding: 1px 5px;
				font-size: 13px;
				float: right;
			}
		}
	}
	.mapToolsPopoverBox {
  		position: absolute;
    	width: 250px;
		bottom: 35px;
		right: 80px;
    	z-index: 1;
		padding: 0 15px;
		max-width: 300px;
		box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12);
		border-radius: 4px;
		color: rgba(0, 0, 0, 0.87);
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		background-color: #fff;
	}
	
}