.mapLayersPopover .MuiPaper-root {
	padding:0 15px;
	width: 350px;
	overflow-y: hidden;
	h5 {
		font-weight: bold;
	}
	
	.mapLayerDropdown {
		width: 100%;
	}
}

// HIDE DEFAULT ZOOM BUTTONS FROM OPEN LAYERS
.ol-zoom.ol-control {
	display: none;
}
.ol-rotate.ol-control {
	display: none;
}
.ol-attribution.ol-control {
	// display: none;
}