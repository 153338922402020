.mapLayerDropdown{
	.panel {
		width: 50%;
		float: left;
		margin: 0;
		&.baseMaps {
			> .MuiFormGroup-root {
				> label {
					> .MuiFormControlLabel-label {
						font-size: 1.1rem;
					}
				}
			}
		}
		> .MuiFormControlLabel-root {
			width: 100%;
			margin: 0;
			> .MuiFormControlLabel-label {
				font-size: 1.1rem;
			}
		}
	
	
		.baseMapsListBox {
			margin-left: -10px;
			> .baseMapsListBoxItem {
				padding: 0;
				> .baseMapsListBoxItemRadioBtn {
					min-width: auto;
				}
				> .baseMapsListBoxItemTitle {
					span {
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}
