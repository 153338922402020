.mapBottomLeftElements {
  position: absolute;
  bottom: 0;
  z-index: 90;
  left: 0;
  color: #293239;
  width: auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 20px;
  > li {
    float: left;
    font-size: 10px;
  }
  #mapAttrDisplay {
    background: rgba(255, 255, 255, 0.5);
    float: left;
    padding: 3px;
    .ol-attribution {
      > ul {
        display: inline;
        > li {
          display: inline;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
      > button {
        display: inline;
        padding: 0 2px;
        background: transparent;
        border: none;
      }
      &.ol-logo-only {
        display: none;
      }
    }
  }
  #mainCoordDis {
    background: rgba(255, 255, 255, 0.5);
    min-width: 185px;
    text-align: center;
    float: left;
    padding: 3px 4px;
  }
  #scaleLineDIS {
    color: #293239;
    float: left;
    padding: 0 4px 7px 0;
    background: rgba(255, 255, 255, 0.5);

    .ol-scale-line {
      border-bottom: 1px solid #3d3d3d;
      text-align: center;
      border-right: 1px solid #3d3d3d;
      border-left: 1px solid #3d3d3d;
    }
  }
}
#alertMapAttrDisplay {
  position: relative;
  top: -24px;
  left: 9px;
  background: rgba(255, 255, 255, 0.5);
  float: left;
  padding: 3px;
  .ol-attribution {
    > ul {
      display: inline;
      > li {
        display: inline;
        img {
          width: 18px;
          height: 18px;
        }
      }
    }
    > button {
      display: inline;
      padding: 0 2px;
      background: transparent;
      border: none;
    }
    &.ol-logo-only {
      display: none;
    }
  }
}
