
// .glyphicon-none:before {
//     content: "\e013";
//     color: transparent !important;
// }



// button > i.fa-caret-down {
//    margin-left:8px;
// }

// .divider {
//     height: 1px;
//     margin: 9px 0;
//     overflow: hidden;
//     background-color: #eee;
// }

/*
*
* TREE VIEW CHECKBOXES
*


.groups {
    width:calc(100% - 20px);
    padding-left:20px;
    float:left;
}
.groups > li {
    width:100%;
  
}
.groups > li > label {
    border-bottom: 1px solid #eee;
    width: 100%;
    padding-left: 20px;
}
.groups > li > input[type=checkbox] {
    position:relative;
    top:0;
}
*/

/*
#Asset-body > div > scc-collection > div > ul.treeview {
    padding:0!important;
}
#Asset-body scc-device > li > div:hover label{
    text-decoration: underline;
}
#Asset-body .treeview scc-group > li > .groups .device-groups-name {
    position:relative;
    top:3px;
}
#Asset-body .treeview scc-group > li > .groups .device-groups-name > .badge {
    margin-bottom:2px;
}
.main-collection > scc-collection > div > .treeview {
    padding:0!important;
    border:none;
}
.main-collection > scc-collection > div > .treeview > scc-group > li > div.groups:before {
     content: "";
     margin-left:-11px!important;;
}
.treeview-test ul {
    padding-left:20px;
}
.treeview-test ul li {
    border-bottom:1px solid #eee;
}

scc-collection.ng-hide > .treeview {
    border:none;
}
*/



// .treeview {
//     margin: 0;
//     width: 100%;
//     float: left;
//     padding-left:20px;
//     /*margin-bottom: 2px;*/
// }
// .treeview li {
//     width:100%;
//     float:left;
//     margin-top:1px;
// }
// .treeview > scc-device:last-child > li,
// .treeview > scc-device:last-child > li > div,
// .treeview > scc-group:last-child > li {
//     border:none;
// }
// .treeview i.fa-square-o,
// .treeview i.fa-check-square,
// .treeview i.fa-minus-square-o {
//     width: 14px;
//     font-size: 16px;
//     position: relative;
//     top: 1px;
// }
// scc-group > li > div {
//     background: #ddd!important;
//     border: 0px solid transparent;
//     margin-bottom: 1px;
//     border-left: 5px solid transparent!important;
// }
// scc-group > li > div {
//     margin-bottom: 1px;
// }
// /* ACTIVE STATE FOR GROUPS */
// scc-group > li > div.active {
//     background:#91acbf!important;
// }
// scc-group > li > div.active > label {
//     color: #293239;
// }
// /*
// scc-group > li > div.active:before {
//     content: "\f115";
//     font-family: FontAwesome;
//     font-style: normal;
//     font-weight: normal;
//     text-decoration: inherit;
//     color: #293239;
   
//     padding-right: 0.5em;
//     position: relative;
//     top: 0;
//     left: -12px;
//     margin-left: -16px;
// }
// */
// scc-group > li > div.groups:before,
// scc-group > li > div.has-subgroup:before {
//     content: "\f07b";
//     font-family: 'FontAwesome';
//     font-style: normal;
//     font-weight: normal;
//     text-decoration: inherit;
//     color: #293239;
//     font-size: 12px;
//     padding-right: 0.5em;
//     position: relative;
//     top: 0;
//     left: -2px;
//     margin-left: -20px;
//     opacity:0;
// }
// scc-group > li > div.has-subgroup:before {
//     opacity:0.4;
// }
// scc-group > li > div.has-subgroup.active:before {
//     content: "\f07c";
//     font-family: 'FontAwesome';
//     font-style: normal;
//     font-weight: normal;
//     text-decoration: inherit;
//     color: #293239;
//     font-size: 12px;
//     padding-right: 0.5em;
//     position: relative;
//     top: 0;
//     left: 0;
//     margin-left: -22px;
// }
// scc-group > li > div.active .scc-menu-list-btn > .btn > i.fa {
//     opacity:1;
// }
// scc-group > li > div,
// scc-user > li > div,
// scc-poi > li > div,
// scc-geofence > li > div,
// scc-device > li > div {
//     float:left;
//     width:100%;
//     padding: 0 5px;
//     background-color: #eee!important;
// }
// scc-user > li > div,
// scc-poi > li > div,
// scc-geofence > li > div,
// scc-device > li > div {
//     background-color: #fff!important;
//     margin-top: -2px;
//     border-bottom: 1px solid #eee;
//     padding: 0;
//     margin-top: 1px;
//     padding-left: 10px;
// }
// scc-device > li > div > i.fa {
//    position: relative;
//    top: 7px;
//    left: 1px;
//     margin-right: 8px!important;
// }
// scc-device > li > div > label {
//    cursor:pointer;
// }
// scc-device > li > div > label > input[type=checkbox] {
//     margin-right: 8px;
// }

// .device-groups-li {
//     border-bottom: 1px solid #eee;
// }

// scc-group > li > div.groups:hover {
//     cursor: pointer;
// }
// .treeview li > div > input[type=checkbox],
// .treeview li > div > input[type=radio] {
//     margin-right:4px;
//     position: relative;
//     left: 0;
//     top: 2px;
//     cursor: pointer;
// }
// .treeview li > div input[type=checkbox]:disabled {
//     cursor: not-allowed;
// }
// .treeview li > div > label,
//  scc-user > li > div > label,
//  scc-geofence > li > div > label,
//  scc-poi > li > div > label {
//     margin-bottom: 0;
//     margin-left: 0;
//     // padding: 3px 0px 2px 0px;
//     /*color: #777; */
//     font-weight: 300;
// }
// .treeview li > div > label > div {
//     float:right;
// }
// .treeview li > div.groups > label {
//     cursor: pointer;
// }
// .treeview li > div > label > .badge {
//     margin-left:5px;
//     position: relative;
//     top: -1px;
// }
// .treeview li > label.hover {
//     background:#eee;
// }
// /* Unchecked styles */
// .treeview li > div > label > .device-groups {
//   color:initial;
//   font-size:14px;
//   font-weight: bold;
//   color:#293239;
  
// }
// .treeview li > div > i.fa {
//     width:17px;
//    text-align: center;
// }

.lockSettingsIconUA {
    &.toggleLockSettingsUA {
        background: yellow;
    }
    .icon-small {
        width: 35px;
        height: 35px;
    }
}

.uaForm {
    // border-top:1px solid #eee;
    padding-top: 20px;
    .form-group {
        margin-bottom:15px;
    }
    label {
        margin-bottom:0;
        padding:10px 0;
    }
    .icon-small {
        fill: #2793e6;
    }
    .ios-toggle-box {
        margin-top: 8px;
    }
    .select {
        &.errorClass {
            border:1px solid red;
        }
        select {
        text-align: right;
        }
    }
}

/*
* ASSET GROUPS 
* Global styles when viewing assets or asset groups in the entire platform
* 
*
*/
.Asset-menu .menuScrollHeight {
    margin-top:100px;
    margin-bottom:50px;
    
}
#Asset-body scc-device > li > div {
    cursor:pointer;
}
#asset_menu_search {
    padding:6px 12px;
    font-size:14px;
}
.asset_addGroups_box{
    position:absolute;
    bottom:0;
    background:#293239;
    height:50px;
    left:0;
}
.asset_addGroups_box > button {
    margin-top: 8px;
}
.asset_filter_box {
	padding: 10px 0;
    float: left;
    width: 100%;
    position: absolute;
    top: 50px;
}
.asset_create_new-BG,
.user_perms {
    position: absolute;
    z-index: 39;
    width: 400px;
    height: auto;
    top: 0;
    padding: 10px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    -moz-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    -ms-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    -o-box-shadow: 0 0 8px rgba(0,0,0,0.6);
    box-shadow: 0 0 8px rgba(0,0,0,0.6);
    right: 100%;
    margin-right: 5px;
}
.user_perms {
    padding: 10px 2px 0;
}

.device-info-box {
   width: 100%;
   margin-top: -2px;
   padding:10px;
}


.settingsCCU button.btn-link:hover {
    color:#293239!important;
}
.settingsCCU-label {
    position: absolute;
    top: 30px;
    right: 25px;
    padding: 10px;
}
.device-edit-speed-box {
    width: 100%;
    float: left;
}
   
.user_perms .permList {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    margin: 0;
    margin-top: 10px;
}
.user_perms .permList > li {
    margin-bottom: 2px;
    background:#ddd;
    padding: 5px 0 1px;
}
.user_perms .permList > li:hover {
    background: #ccc;
}
.asset_create_new-BG:after {
	content: '';
	position: absolute;
	top: 7px;
	left: 100%;
	margin-top: -6px;
	width: 0;
	height: 0;
	border-left: 6px solid #fff;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
}
.asset_create_new-BG > h4,
.user_perms > h4 {
    width:80%;
    margin-top: 0;
    text-align:center;
}

.loadingGifAlerts {
	position: relative;
	-webkit-opacity: 0.8;
	-moz-opacity: 0.8;
	-ms-opacity: 0.8;
	-o-opacity: 0.8;
	opacity: 0.8;
	left:40%;
	margin:20px;
	font-size: 16px;
}

#near_resp_list.asset_groups_box > li,
#alert_rules_list.asset_groups_box > li {
    border-left: 5px solid transparent;
}

.editAssetGroups .dropdown > button,
.editAssetGeofence .dropdown > button {
    width:100%;
    text-align: left;
}
.editAssetGroups .dropdown .dropdown-menu, 
.editAssetGeofence .dropdown .dropdown-menu {
    width:100%;
}
.editAssetGroups .dropdown > button span.caret, 
.editAssetGeofence .dropdown > button span.caret {
    margin-top: 7px;
}







/* MASTER DATA FILE */
.mainMenuBox 
    > .panel-footer {
        .master-data-file-box {
        bottom: 44px;
        right: 1px;
        width:100%;
        > .generate-file-button {
            padding: 2px 5px !important;
            position: absolute;
            right: 40px;
            top: 3px;
        }
        > .close {
            font-size:25px;
            padding:5px;
        }
        > .caret{
            margin-top:8px;
        }
    }
}



.device-settings-geofence .dropup {
    width: 33.5%;
}
.device-settings-geofence .dropup > .btn {
    border-radius: 0;
    text-transform: uppercase;
    width: 100%;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 34px;
    padding: 6px 6px;
    font-size: 12px;
    line-height: 1.8;
    text-align: left;
    border-right: none;
}
.device-settings-geofence .dropup > .btn > .caret {
    position: absolute;
    right: 10px;
    top: 15px;
}
.device-settings-geofence .dropup > .dropdown-menu {
    left:0;
    padding:5px;
    width:140%;
    display:block;
}
.device-settings-geofence-box {
    min-height: 34px;
    text-align: left;
    float: right;
    width: 66.5%;
    padding: 6px 12px 0;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    /* border-radius: 4px; */
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.device-settings-geofence-box > p {
    margin:0;
}
.select-geofence-cargo li > label {
    float: left;
    width: 100%;
    padding: 2px 5px;
    background-color: #eee !important;
    margin-bottom: 3px;
    font-weight: 100;
    font-size: 12px;
}





/* EDIT GROUP FORM */
/*
.edit-Group-select-asset,
.edit-Group-select-user {
    max-height:400px;
    overflow:auto;
    border: 1px solid #eee;
}
*/
.edit-Group-select-asset > scc-collection > div > .treeview {
    padding:0;
}
.edit-Group-select-parent-btn {
    width:100%;
}
.edit-Group-select-parent-btn > input {
    width:80%;
    margin:0;
    float:left;
}
.edit-Group-select-parent-btn > button {
    width: 20%;
    float: right!important;
    padding: 5px!important;
    border: 1px solid #ccc!important;
    opacity: 1!important;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
}
.edit-Group-select-parent-btn > button.btn-primary {
    color: #fff!important;
    background-color: #337ab7!important;
    border-color: #2e6da4!important;

}
.edit-Group-select-parent-box {
   position: relative;
    z-index: 2;
    background: #fff;
    padding: 10px;
    border: 1px solid #eee;
    max-height: 465px;
    overflow:auto;
    width: 100%;
    right: 15px;
    border-color: #ccc;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 0, 0, .6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(0, 0, 0, .6);

}
.mainMenu-title > h3 {
    margin:0;
    margin:5px 0;
    > .icon {
        margin-bottom: -7px;
    }
}
/*  END */