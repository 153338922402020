/* CSS used here will be applied after bootstrap.css */

.Message-menu {
  width: 950px;
  /* HEADER */
  /* BODY */
  /* FOOTER */
  /* .chip {
  	 display: inline-block;
  	 padding: 5px 10px;
  	 font-size: 12px;
  	 border-radius: 25px;
  	 background-color: #e0e0e0;
  	 line-height: 12px;
  	 margin-right: 4px;
  	 margin-bottom: 4px;
  	 > i.fa {
  		 margin:0;
  	}
  	 .badge {
  		 position: relative;
  		 left: 4px;
  		 padding: 3px 3px;
  		 i.fa {
  			 float: left;
  			 margin:0;
  		}
  	}
  	 .closebtn {
  		 padding-left: 10px;
  		 color: #888;
  		 float: right;
  		 font-size: 18px;
  		 cursor: pointer;
  		 &:hover {
  			 color: #000;
  		}
  	}
  }
   */
  .menu-transclude-footer {
    width: 100%;
  }
  .table tbody > tr {
    > td.ellipsis {
      max-width: 140px;
    }
    td {
      font-size: 13px;
    }
  }
  &.mainMenuBox .panel-body {
    min-height: 216px;
    padding: 0;
  }
  .mail-toolbar-header {
    padding: 6px 0;
    padding-left: 150px;
    width: 845px;
    height: 45px;
    /* position: relative;
     top: 8px;
     left: 50px;
     */
    > {
      .messageHambergerMenu {
        display: none;
      }
      .filterMessagesBox {
        > .checkAllBtn {
          padding: 0 !important;
          > label.checkboxCont {
            padding: 5px 10px;
            height: 32px;
            .icon-small {
              top: 3px;
            }
          }
        }
        .dropdown-toggle {
          padding: 6px 12px !important;
          height: 34px;
          > .showAlertIcon {
            position: absolute;
            top: 2px;
            font-size: 16px;
            padding: 1px 3px;
            border-radius: 50%;
            height: 12px;
            width: 12px;
            display: block;
            background: #d43f3a;
            float: right;
            right: 2px;
          }
        }
        .dropdown-menu {
          left: -1px !important;
          margin-top: -1px;
          > li {
            width: 100%;
          }
        }
      }
    }
    .search-messages {
      width: 60%;
      float: right;
      > .input-group {
        > .form-control {
          width: 100%;
          &.error {
            border-color: #a94442 !important;
            &:focus, &:active {
              border-color: #a94442 !important;
            }
          }
        }
        .search-messages-errors {
          position: absolute;
          top: 100%;
          z-index: 2;
          font-size: 12px;
          padding: 2px 5px;
          background: #a94442;
          color: #fff;
          content: "";
          float: right;
          width: 100%;
          right: 0;
          p {
            margin-bottom: 0;
            font-weight: bold;
          }
        }
        > {
          .searchMessageBtn {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 3;
            height: 34px;
          }
          .clearSearchBtn {
            position: absolute;
            top: 0;
            right: 40px;
            z-index: 3;
            padding: 6px;
            height: 34px;
          }
        }
      }
      .btn-group > button.btn, button.btn {
        padding: 6px 12px;
        font-size: 14px;
        height: 34px;
      }
    }
  }
  .mail-toolbar-footer .btn-group > button.btn {
    padding: 4px 5px 2px !important;
    /* font-size: 14px;
     */
    /* height: 33px;
     */
    margin-left: 1px;
    &:disabled {
      background: #adadad;
      opacity: 0.4;
      border: 1px solid #adadad;
      &:hover {
        background: #adadad;
        opacity: 0.4;
        border: 1px solid #adadad;
      }
    }
  }
  .compose-message-select-recipients {
    > .btn-group {
      > .btn {
        padding: 10px !important;
        margin-bottom: 5px;
      }
      .dropdown-menu {
        left: 0 !important;
        width: 400px !important;
        padding: 0 5px;
        position: absolute;
        top: calc(100% - 5px);
        float: left;
        width: auto;
        margin: 2px 0 0;
        background-color: #fff;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        max-height: 270px;
        > scc-user {
          width: 100%;
          max-height: 220px;
          overflow: auto;
          float: left;
        }
        > .main-collection {
          > scc-collection > div > .treeview {
            max-height: 220px;
            overflow: auto;
            float: left;
            padding: 0;
            overflow-x: hidden;
            // width: calc(100% + 30px);
            // /* border: 1px solid red;
            //  */
            // margin-left: -15px;
          }
        }
       
        // &.compose-message-select-users {
        //   overflow-x: hidden;
        //   overflow-y: auto;
        //   width: 100%;
        // }
      }
    }
    .dropdown-menu > scc-collection > div > .treeview {
      width: 100%;
      max-height: 220px;
      overflow: auto;
      float: left;
    }
  }
  .page-numbers {
    font-size: 16px;
    position: relative;
    top: 3px;
    right: 10px;
    color: #fff;
  }
  .fileinput-button {
    float: left;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
    background: none repeat scroll 0 0 #eee;
    border: 1px solid #e6e6e6;
    input {
      cursor: pointer;
      direction: ltr;
      font-size: 23px;
      margin: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(-300px, 0px) scale(4);
    }
  }
  .thead-inverse th {
    color: #293239 !important;
    background-color: #ddd;
    padding: 10px 5px 9px !important;
    text-transform: uppercase;
  }
  .table .fa.fa-sort {
    opacity: 0.4;
  }
  .new-message-alert {
    position: absolute;
    top: 45px;
    width: 100%;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 6px 10px;
  }
  .messageTabs {
    position: absolute;
    z-index: 2;
    width: 200px;
    float: left;
    height: auto;
    .list-group {
      margin: 0;
      float: left;
      width: 100%;
      height: 100%;
      background: #fff;
    }
    button {
      padding: 0 10px;
      .list-group-item-tab > .icon-small {
        width: 18px;
        height: 18px;
        top: 3px;
        opacity: 0.6;
      }
    }
    .list-group-item {
      padding: 10px 10px 10px 15px;
      background-color: #fff;
      min-height: 38px;
      opacity:0.8;
      &:first-child {
        border-top: 1px solid #ddd;
        opacity:1;
      }
      &.selected-checkbox-badge {
        background: #ffc !important;
        color: #293239 !important;
        font-weight: bold !important;
        > span {
          width: 100%;
          text-align: center;
          > scc-clear-badge {
            > .emptySelectionBtn {
              margin-top: -5px;
              > .icon-small {
                left:-6px;
              }
            }
          }
        }
      }
      .list-group-item-title {
        margin-left:6px;
      }
      .list-group-item-tab {
        max-width: 100px;
        float: left;
      }
      .list-group-item-count {
        max-width: 78px;
        margin-top: 3px;
        text-align: right;
        font-size:13px;
      }
      &.active {
        color: #293239;
        background-color: #ddd;
        border-color: #ddd;
        opacity:1;
        &:hover, &:focus {
          color: #293239;
          background-color: #ddd;
          border-color: #ddd;
        }
        .list-group-item-tab {
          .list-group-item-title {
            font-weight: bold;
          }
          > .icon-small {
            opacity: 1;
          }
        }
        .list-group-item-count {
          font-weight: bold;
        }
        > .badge {
          color: #fff;
          background-color: #777;
          font-weight: bold;
        }
      }
      &.loadingMessages {
        cursor: not-allowed;
      }
      > .badge {
        font-weight: initial;
      }
    }
  }
  .loaderMessage {
    position: absolute;
    height: calc(100% - 95px);
    width: calc(100% - 200px);
    background: rgba(238, 238, 238, 1);
    background: #fff;
    z-index: 1;
    vertical-align: middle;
    > h3 {
      line-height: 100px;
      .icon {
        height: 20px;
      }
    }
  }
  .messageContainer {
    width: calc(100% - 200px);
    float: right;
    .list-group {
      border-left: 1px solid #ddd;
      margin: 0;
      .list-group-item.view-message-buttons {
        height: 55px;
        .btn-group > .btn {
          padding: 6px 12px;
        }
      }
    }
    .list-group-item:last-child {
      border: none !important;
    }
    .table {
      margin: 0;
      margin-left: -1px;
      width: calc(100% + 1px);
      font-size: 12px;
      tbody {
        > tr {
          > td {
            vertical-align: middle;
            padding: 10px 5px 10px 0;
            &.message-checkbox {
              text-align: center;
              padding-left: 5px;
              width:40px;
            }
            .unauthorized-user {
              position: relative;
              top: -2px;
            }
          }
        } 
      }
    }
  }
  .unread {
    background: #dff0d8 !important;
  }
  .checked-message {
    background: #ffc !important;
  }
  .compose-message-box {
    margin: 0;
    // padding: 0 15px;
    > .form-control {
      float: left;
      display: block;
      width: 100%;
      padding: 6px;
      color: #555;
      background-color: #fff;
      background-image: none;
      height: auto !important;
      max-height: 150px;
      min-height: 40px;
      overflow: auto;
      > .chip {
        // margin: 0 4px 4px 0;
      }
    }
  }
  .message-sent-success {
    background-color: #dff0d8;
    color: #3c763d;
  }
  .message-sent-pending {
    background-color: #fcf8e3;
    color: #8a6d3b;
  }
  .message-sent-failed {
    background-color: #f2dede;
    color: #a94442;
  }
  .view-message-buttons {
    width: 100%;
    padding: 10px 0 0;
    border-bottom: 1px solid #eee;
  }
  .view-message-header h3 {
    margin: 0;
    /* margin-top: 5px;
     position: absolute;
     top: 10px;
    */
  }
  .view-message-recipients {
    padding: 15px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .view-message-attachment a {
    padding: 0 !important;
    font-size: 12px;
  }
  .view-message-date {
    padding: 0;
    font-size: 14px;
  }
  .view-message-label {
    display: block;
    font-weight: bold;
    margin: 0;
  }
  .view-message-info {
    margin-top: 0;
    word-wrap: break-word;
    .badge > i.fa {
      margin: 0;
    }
  }
  .message-compose {
    .dropdown-menu-messages{
      left: 0 !important;
    width: 400px !important;
    padding: 0 5px;
    position: absolute;
    z-index: 5;
    // overflow-x: hidden;
    top: calc(100% - 5px);
    float: left;
    width: auto;
    margin: 2px 0 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    max-height: 270px;
    .main-collection > scc-collection > div > .treeview{
      overflow-y: scroll;
    height: 210px;
    overflow-x: hidden;
    }
}

    .filenameType {
      width: 40% !important;
    }
    .filenameTypeDetail {
      width: 40% !important;
      float: left !important;
      border: none;
      box-shadow: none;
    }
    .btn-default .badge {
      background-color: #777;
    }
  }
  .test {
    word-wrap: break-word;
  }
  .message-compose .message-compose-validation-list {
    position: absolute;
    top: 5px;
    padding: 5px 10px;
  }
  .showMessageTabs {
    display: inline-block;
  }
}

@media (max-width: 960px) {
  .Message-menu {
    width: calc(100% - 10px);
    position: fixed;
    top: 60px;
    right: 5px;
    height: auto;
    left: 5px;
    .mail-toolbar-header {
      width: 650px !important;
      padding-left: 0;
      .messageHambergerMenu {
        /* float: right;
         */
        display: block;
        margin-right: 10px;
        height: 34px;
      }
      .filterMessagesBox .dropdown-menu {
        position: absolute;
        padding: 5px 0;
        clear: both;
        font-weight: normal;
        line-height: 1.42857;
        white-space: nowrap;
        background-color: #fff;
        border: 1px solid #ccc;
        border: 1px solid rgba(0, 0, 0, 0.15);
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        background-clip: padding-box;
        border-radius: 0;
        > li > a {
          display: block;
          padding: 3px 20px;
          clear: both;
          font-weight: normal;
          line-height: 1.42857;
          white-space: nowrap;
        }
      }
    }
    .panel-body {
      height: 100%;
      .messageTabs {
        width: 48px;
        height: calc(100% - 95px);
        // box-shadow: 2px 0px 8px grey;
        box-shadow: 1.0rem 0 1.8rem -0.4rem rgba(0,0,0,.1);
        &.expand {
          width: 203px;
          z-index: 3;
        }
        .showMessageTabs {
          display: none;
        }
      }
      .messageContainer {
        width: calc(100% - 48px);
        .loaderMessage {
          width: calc(100% - 48px);
        }
      }
    }
    .messageTabs .list-group {
      height: 100%;
    }
  }
}

@media (max-width: 760px) {
  .Message-menu {
    &.mainMenuBox .panel-body .message-compose {
      margin: 0 15px;
      .compose-message-box {
        padding-right: 0;
        width: calc(100% + 15px);
      }
    }
    .mail-toolbar-header {
      width: 550px !important;
      .search-messages {
        margin-left: 10px;
        float: left;
      }
    }
  }
}

@media (max-width: 660px) {
  .Message-menu .mail-toolbar-header {
    width: 445px !important;
  }
}