/*
*
* POI MARKER
*
*/
// #olMapPoiPopup {
//     &.popUpTop {
//         top: -110px;
//         left: -50px;
//         > .arrow::before {
//             top: 128px;
//             left:10px;
//             border-width: 10px 10px 0 10px;
//             border-color: #f5f5f5 transparent transparent transparent;
//         }
//     }
// }
// #olMapPoiPopup.popUpTop.popUpLeft {
//     right: 10px;
//     left: initial;
//     > .arrow::before {
//         top: 128px;
//         left:initial;
//         right:10px;
//         border-width: 10px 10px 0 10px;
//         border-color: #f5f5f5 transparent transparent transparent;
//     }
// }
// #olMapPoiPopup.popUpBottom.popUpLeft {
//     right: 5px;
//     left: initial;
//     > .arrow::before {
//         left: initial;
//         right: 15px;
//     }
// }

/* POI */
.poi-list.list-group {
  margin-top: -20px;
}
.poi-list.list-group .list-group-item {
  padding: 5px 0;
}
.poi-title {
  /*padding-left:20px!important;*/
}
.poi-title > i.fa {
  margin-left: 8px;
}
.POI-menu .panel-group .panel .sync-acknowledge-btn > .btn {
  padding: 0 12px !important;
  margin-top: 1px;
}
.new-poi-tactical {
  background: #ccc !important;
}
.poi-marker-img-box {
  margin-left: -8px;
  width: 45px;
  height: 25px;
}
img.poi-marker-img-platform,
img.poi-marker-img {
  max-height: 25px;
  height: 15px;
  max-width: 45px;
  display: block;
  margin: 0px auto;
}
img.poi-marker-img-nato {
  max-height: 25px;
  max-width: 45px;
  display: block;
  margin: 0px auto;
}
.poi_marker_selection {
  width: 100%;
  float: left;
  margin-top: 10px;
}
.poi_marker_selection .poi-marker-img {
  height: 30px;
}
.poi_marker_box {
  margin: 0;
  margin-top: 10px;
  > scc-locate {
    > .scc-menu-list-btn {
      position: absolute;
      top: 4px;
      right: 10px;
    }
  }
}
.poi_marker_image {
  min-height: 35px;
  max-height: 45px;
  max-width: 85px;
  border: 1px solid #ccc;
  border-radius: 1px;
  display: table-cell !important;
  text-align: center;
  vertical-align: middle;
}
.poi_select_icon_button {
  width: 100%;
}
.poi_select_icon_box {
  width: 715px;
  height: 250px;
  padding: 5px;
  overflow: auto;
}
.poi_select_icon {
  overflow: auto;
  width: 100%;
}
.poi_select_icon_box > li > a {
  width: 99px;
  height: 55px;
  border: 1px solid #eee;
  padding: 2px 0 !important;
  display: table-cell !important;
  text-align: center;
  vertical-align: middle;
}
.poi_select_icon_box > li > a * {
  vertical-align: middle;
}

/*
.poi_select_box > ul.dropdown-menu {
    width:665px;
    padding:5px;
    max-height: 340px;
    overflow: auto;
}
.poi_select_box > ul.dropdown-menu > li {
    float: left;
    height: 80px;
}
.poi_select_box > ul.dropdown-menu > li > a {
    padding: 0 5px;   
}
.poi_select_box > ul.dropdown-menu > li > a > img {
   width:75px; 
}
*/

/* NATO ICONS */
.nato .friendly {
  background: #92cce3;
}
.nato .neutral {
  background: #abd7a2;
}
.nato .hostile {
  background: #e9817d;
}
.nato .unknown {
  background: #f5f184;
}

.nato-icon {
  height: 50px;
  width: 50px;
  border: 2px solid #000;
  float: left;
}

.POI-menu .panel-body .tab-content {
  padding: 20px 0 10px 0;
}
