.CustomDialog{
    .MuiDialog-container{
        min-height: 25%;
        max-height: 50%;
        top: 100px;
    }
    .MuiButton-label{
        font-size: 12px;
    }
    .MuiDialogContentText-root{
        font-size: 14px;
        margin-right:15px;
    }
}