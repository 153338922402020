.pagination {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    float: right;
    display: flex;
  }
  li {
    list-style: none;
    display: inline;
    text-align: center;
  }
  button {
    cursor: pointer;
    padding: 6px 8px;
    display: block;
    float: left;
  }
  .active > {
    button {
      background-color: #337ab7 !important;
      color: #293239;
      &:hover,
      &:focus {
        color: #293239;
      }
    }
    span {
      color: #293239;
      &:hover,
      &:focus {
        color: #293239;
      }
    }
  }

  .disabled > {
    button {
      cursor: not-allowed;
      background-color: #cccccc;
      color: #aaaaaa;
      &:hover,
      &:focus {
        background-color: #cccccc;
        color: #aaaaaa;
      }
    }
  }
}
.pagination > li > button:hover,
.pagination > li > span:hover,
.pagination > li > span:focus {
  z-index: 2;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > li > button,
.pagination > li > span {
  color: #293239;
  background-color: #ddd;
  border: 1px solid #ccc;
  position: relative;
  float: left;
  padding: 6px 8px;
  line-height: 1.42857;
  text-decoration: none;
}
.pagination button {
  cursor: pointer;
  padding: 6px 8px;
  display: block;
  float: left;
}
