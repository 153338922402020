#olMapZoomButtons {
	position: absolute;
	z-index: 507;
	right: 5px;
	bottom: 5px;
	// border: 1px solid red;
	> div {
		> .MuiButtonGroup-root {
			button {
				width: 35px;
                height: 35px;
                min-width: 35px;
                background: #fff;
                .MuiButton-startIcon {
                    margin: 0;
                }
				&.zoomLevels {
					font-size: 1.2rem;
				}
			}
		}
	}
	.ol-zoom-in {
		display: none;
	}
	.ol-zoom-out {
		display: none;
	}
}
