
.DeviceAlertBarAbsolute {
  position:absolute;
  top: -30px;
  right: 0;
}
.DeviceAlertBar {
  box-shadow: none !important;
  color: #293239 !important;
  float: right;
  width: fit-content;
  display: table-row;
  .listing {
    min-height: initial;
    margin: 5px;
    align-items: flex-end;
    display: block;
    span {
      //width: calc(100%/7)!important;
      display: table-cell;
    }
  }

  .Emergency {
    //border-left: 4px solid #d9534f;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #d9534f;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Geofence {
    //border-left: 4px solid #f9840f;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #f9840f;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: block;
      width: fit-content;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Report {
    //border-left: 4px solid #3d3d3d;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #3d3d3d;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Speed {
    //border-left: 4px solid #c43cc4;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #c43cc4;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Non-Report {
    //border-left: 4px solid #67d06c;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #67d06c;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Cargo {
    //border-left: 4px solid #148c90;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #148c90;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
  .Vehicle {
    //border-left: 4px solid #587fab;
    padding-left: 5px;
    position: relative;
    .MuiSvgIcon-fontSizeSmall {
      background-color: #587fab;
      //  we define small size
      font-size: 12px;
    }
    span {
      color: white;
      background-color: #d9534f;
      font-size: 8px !important;
      font-weight: bold;
      position: absolute;
      display: flex;
      justify-self: center;
      align-items: center;
      height: 10px;
      min-width: 10px;
      width: fit-content;
    }
  }
}
