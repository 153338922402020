.mapSettingsPopover .MuiPaper-root {
	padding:0 15px;
	max-width: 300px;
	h5 {
		font-weight: bold;
	}
	.mapSettingsMenu li {
		display: block;
		width: 100%;
		float: left;
		padding: 5px 0;
		border-bottom: 1px solid #eee;
		span {
			float: right;
		}
	}
}