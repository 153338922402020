$bg-color: #222b30;

$main-color: #293239;

$text-color-main: #293239;
$box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

$nav-tabs-bg: $main-color;
$nav-tabs-active: $bg-color;
$nav-tabs-border: $bg-color;
$nav-tabs-text: #5b7182;
$text-color-white: #fff;
$background-white: #fff;

/*
*	ALERTS
*   previously used alert red color #d9534f
*/

$emergency-alert-color: #d52b1e;
$speed-alert-color: #c43cc4;
$geofence-alert-color: #f9840f;
$nonreport-alert-color: #67d06c;
$cargo-alert-color: #148c90;
$report-alert-color: #222b30;
$vehicle-alert-color: #587fab;

$error-border-color: #e42a2a;
$error-color: red;
$input-error-border-color: #a94442;
$input-error-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
$input-error-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
$input-group-form-control-readonly-background:#fff;
$input-group-form-control-readonly-border:1px solid #ddd;
$input-group-form-control-readonly-color: #293239!important;

$btn-primary-focus-color: #293239!important;
$form-control-border-color:#ddd;
$form-control-color: #293239!important;

$badge-success-color: #fff!important;
$badge-success-background-color: #5cb85c!important;
$badge-success-border-color: #4cae4c!important;
$badge-danger-color: #fff!important;
$badge-danger-background-color: #d9534f!important;
$badge-danger-border-color: #d43f3a!important;
$badge-warning-color: #fff!important;
$badge-warning-background-color: #f0ad4e!important;
$badge-warning-border-color: #eea236!important;
$badge-primary-color: #fff!important;
$badge-primary-background-color: #337ab7!important;
$badge-primary-border-color: #2e6da4!important;

$label-danger-background-color: #d43f3a!important;
$yellow-background-color: #FC0!important;

$enable-success-border-left: 5px solid #3c763d!important;
$enable-success-color: #3c763d;
$enable-success-background-color: #dff0d8!important;

$alert-default-color:#293239;
$alert-default-background-color: #fff;
$alert-default-border-color: #5b7182;

$tooltip-left-title-hover-after-background-color: #000;
$tooltip-left-title-hover-after-color: #fff;