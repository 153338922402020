:root {
  --background: url("../images/login_bg.jpg") no-repeat bottom center;
}
button {
  border: 0;
  background-color: transparent;
  font-size: 1em;
}
.loginForm {
  margin: 15px 12px;
}
.login:before {
  background: var(--background);
  position: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  content: "";
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.login {
  background-color: #fff;
  width: 350px;
  margin: 0 auto;
  margin-top: 40px;
  padding: 0;
  -webkit-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  zoom: 1;
}
.login .logo {
  width: 350px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 30px;
  text-align: center;
  background-color: #010101 !important;
  color: #ffffff;
}
.login .logo img {
  position: relative;
  -webkit-filter: drop-shadow(1px 1px 1px #000);
  filter: drop-shadow(1px 1px 1px #000);
  -webkit-filter: drop-shadow(1px 1px 1px #000);
  -moz-box-filter: drop-shadow(1px 1px 1px #000);
  -ms-box-filter: drop-shadow(1px 1px 1px #000);
  -o-box-filter: drop-shadow(1px 1px 1px #000);
  width: 250px;
  display: block;
  margin: 0 auto;
}
.login .logo p {
  margin: 0;
  &.versionText {
    float: right;
    margin-right: 30px;
    color: #768692;
  }
}
.bottomlogoimage {
  position: absolute;
  right: 2em;
  bottom: 2em;
}
.bottomlogoimage img {
  -webkit-filter: drop-shadow(1px 1px 1px #000);
  filter: drop-shadow(1px 1px 1px #000);
  -webkit-filter: drop-shadow(1px 1px 1px #000);
  -moz-box-filter: drop-shadow(1px 1px 1px #000);
  -ms-box-filter: drop-shadow(1px 1px 1px #000);
  -o-box-filter: drop-shadow(1px 1px 1px #000);
  width: 125px;
  display: block;
  margin: 0 auto;
}
.copyright {
  text-align: center;
  margin: 0 auto;
  padding: 10px 10px 0 10px;
  color: #ccc;
  text-shadow: 1px 1px 2px #000;
  position: absolute;
  padding: 5px;
  font-size: 12px;
  margin-left: 60px;
  opacity: 0.6;
}
.errorLoginBox {
  width: 350px;
  margin: 0 auto;
  background: #a94442 !important;
  position: relative;
  top: 0;
  overflow: hidden;
  zoom: 1;
}
.errorLoginBox > .alert {
  color: #f2dede !important;
  margin: 15px;
  line-height: 20px;
}
.messageBox {
  height: 210px;
  align-items: center;
}
.discreetModeVersionTag {
  //background-color: #f2dede;
  display: block;
  position: relative;
  height: auto;
  width: 50%;
  padding: 9px 23px;
  margin-top: 65px;
  margin-bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: #293239;
  color: #ffffff;
}

/* LOGIN */
.login .input-field {
  .MuiInput-root {
    padding: 8px;
    padding-left: 8px;
  }
}
.signIn-btn {
  .MuiButton-textSizeLarge {
    color: rgb(255, 255, 255);
  }
  .MuiButton-label {
    font-size: 1.2rem;
    height: 30px;
  }
}
.login .clear-cache {
  opacity: 0.4;
}
.login .clear-cache:hover {
  opacity: 0.8;
}
.login .forgot-password {
  opacity: 0.9;
  color: #293239;
  float: left;
}
.login .language {
  opacity: 0.9;
}
.login .refresh {
  color: #293239;
  float: left;
}
.login .translate-toggle {
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  color: #293239;
  text-align: center;
  width: 100%;
  /* border: 1px solid red; */
  float: left;
  padding: 5px 15px;
}
/* FORGOT PASSWORD */
.alert {
  border-radius: 1px;
}
.alert-success {
  color: #3c763d;
  background-color: hsl(103, 44%, 89%) !important;
  border-color: #d6e9c6;
  padding: 15px;
}
/* RESET PASSWORD */
.message {
  border-radius: 1px;
  text-align-last: center;
  h2 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.25rem;
  }
}
.message-success {
  color: #000;
  padding: 14px;
}
.message-failure {
  color: #000;
  padding: 14px;
}
.back-btn {
  opacity: 0.9;
  padding: 10px 0;
}
/* MEDIA QUERIES */
@media (max-width: 470px) {
  .login:before {
    background: #293239 !important;
  }
}
