// Modules and Variables
@import "variables.scss";
/* 
   NAVIGATION MENU TOP & LEFT
   BACKGROUND-color: #293239;
   BORDER-color: #3d3d3d;
   FONT -       color: #ccc;
   
   ACTIVE BG    color: #252c32;
   PANIC ALERTS
   ALERT PANIC      #d9534f
   ALERT SPEED      #c43cc4
   ALERT CARGO      #148C90
   ALERT GEOFENCE   #F9840F
*/
/****************************************************************/
* {
	font-family: "proxima-nova", sans-serif;
	font-size: 0.9rem;
}
body {
	color: $main-color;
	background-color: $bg-color;
	transition: 4s;
   overflow-y: hidden;
}
form {
   padding: 0;
}
a {
	color: $text-color-main;
	text-decoration: none;
}
a:hover,
a:focus {
	text-decoration: none;
}
a,
a:active,
a:focus,
button,
button:active,
button:focus {
	outline: none !important; /* Works in Firefox, Chrome, IE8 and above */
}
textarea {
	resize: none;
}
.error {
	border-color: $error-border-color;
	color: $error-color;
}
.input-error {
   border-color: $input-error-border-color;
   -webkit-box-shadow: $input-error-webkit-box-shadow;
   box-shadow: $input-error-box-shadow;
}
.input-group {
	width:100%;
	margin-bottom:-1px;
}
.input-group .form-control[disabled], 
.input-group .form-control[readonly] {
    background:$input-group-form-control-readonly-background;
    border:$input-group-form-control-readonly-border;
    cursor: not-allowed;
    color: $input-group-form-control-readonly-color;
    font-weight:400;
}

/* HEADERS */
h4 {
   font-weight: normal;
   font-size: 18px;
   margin-top: 10px;
   margin-bottom: 10px;
}
h5 {
   font-weight: normal;
   font-size: 1.1rem;
   margin-top: 10px;
   margin-bottom: 10px;
}

/* BUTTONS */
.btn {
   border-radius:1px;
   text-transform: uppercase;  
   /* adds 3 dots to texts overflowing */
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
.btn,
.btn:hover,
.btn:focus,
.btn.focus,
.btn-primary,
.btn-primary:hover, 
.btn-primary:focus, 
.btn-primary.focus {
  color:$btn-primary-focus-color;
}
.form-control {
   border-radius:1px;
   border-color:$form-control-border-color;
   font-weight: 200;
   color: $form-control-color;
}
ul {
  padding-left: 0;
   list-style: none;
}

/* **** IE fixes go here ***** */

@media screen and (min-width:0\0) and (min-resolution: 72dpi) {
   // IE9+ CSS
   .marker-layer > .form-control {
       width: 60%!important;
       height: 100%!important;
   }
}

/*
*
* Badges state
*/
.badge-success {
   color: $badge-success-color;
   background-color: $badge-success-background-color;
   border-color:$badge-success-border-color;
}
.badge-danger {
   color: $badge-danger-color;
   background-color: $badge-danger-background-color;
   border-color: $badge-danger-border-color;
}
.badge-warning {
   color:$badge-warning-color;
   background-color: $badge-warning-background-color;
   border-color: $badge-warning-border-color;
}
.badge-primary {
   color: $badge-primary-color;
   background-color:$badge-primary-background-color;
   border-color: $badge-primary-border-color;
}

.label-danger {
   background-color: $label-danger-background-color;
}
.yellow {
  background-color: $yellow-background-color;
}
.enable-success {
   border-left: $enable-success-border-left;
   color: $enable-success-color;
   // font-weight: bold;
   background-color:$enable-success-background-color;
}

.alert-default {
   color: $alert-default-color;
   background-color: $alert-default-background-color;
   border-color: $alert-default-border-color;
}


/*
*
* Tooltip
*
*/
/* Tooltip on Top */
.tooltip {
   z-index: 10000 !important;
}
.tooltip-inner {
   padding: 4px 8px;
   border-radius: 2px;
}
.tooltip-large  .tooltip-inner {
   min-width: 120px!important;
   padding: 8px 8px;
}
.tooltip-right[data-tooltip]:hover:after,
.tooltip-bottom[data-tooltip]:hover:after,
.tooltip-top[data-tooltip]:hover:after,
.tooltip-bottom-right[data-tooltip]:hover:after,
.tooltip-top-left[data-tooltip]:hover:after,
.tooltip-left[data-tooltip]:hover:after,
.tooltip-left[title]:hover:after {
 content: attr(data-tooltip);
 padding: 4px 10px;
 position: absolute;
 white-space: nowrap; 
 z-index: 5033;
 background-color: $tooltip-left-title-hover-after-background-color;
 border-radius:2px;
 color: $tooltip-left-title-hover-after-color;
 font-size: 14px;
}
.tooltip-right[data-tooltip]:hover:after {
 left: 100%; 
 top: 5px;
}
.tooltip-bottom[data-tooltip]:hover:after {
 left: 0; 
 top: 100%;
}
.tooltip-top[data-tooltip]:hover:after {
 left: 0; 
 bottom: 100%;
}
.tooltip-left[data-tooltip]:hover:after,
.tooltip-left[title]:hover:after {
 right: 100%; 
 top:2px;
}
.tooltip-bottom-right[data-tooltip]:hover:after {
 right: 0; 
 top: 100%;
}
.tooltip-top-left[data-tooltip]:hover:after {
 right: 0; 
 bottom: 100%;
}


.devicebuttons {
   /*width:300px;*/
   width: 50%;
   float:right;
}

.toggle-group {
   padding: 5px 0;
   font-size: 12px;
   cursor: pointer;
}
.toggle-group > i.fa {
   margin:0;
}
