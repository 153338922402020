.mapUtilitiesButtons {
    position: absolute;
    bottom: 5px;
    right: 40px;
    z-index: 508;
    // border:1px solid red;
    margin:0;
    .MuiButtonGroup-contained {
        box-shadow: none;
        div {
            display: inline-block;
            // border:1px solid yellow;
            margin-right: 5px;
            .btn {
                width: 35px;
                height: 35px;
                bottom: 0px !important;
                min-width: 35px;
                background: #fff;
                .MuiButton-startIcon {
                    margin: 0;
                }
            }
        }
    }
    
}
