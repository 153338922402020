.mapLayerDropdown{
	.panel {
		width: 50%;
		float: left;
		margin: 0;
		&.mapOverlays {
			> .MuiFormGroup-root {
				> div label {
					width: calc(100% - 30px);
					margin-right: 0;
					> .MuiFormControlLabel-label {
						font-size: 1.1rem;
					}
				}
			}
		}
		> .MuiFormControlLabel-root {
			width: 100%;
			margin: 0;
			> .MuiFormControlLabel-label {
				font-size: 1.1rem;
			}
		}
	
	
	
	
		.mapOverlaysListBox {
			margin-left: -10px;
			width: calc(100% + 10px);
			.mapOverlaysListBoxItem {
				padding: 0;
				> .mapOverlaysListBoxItemCheckbox {
					min-width: auto;
				}
				> .mapOverlaysListBoxItemTitle {
					span {
						font-size: 1.1rem;
					}
				}
			}
		}
	}
}

